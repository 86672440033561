export enum AuthRoles {
  AUTHENTICATED = "AUTHENTICATED",

  CONTACTS_READ = "CONTACTS_READ",
  CONTACTS_EDIT = "CONTACTS_EDIT",

  MEMBERS_READ = "MEMBERS_READ",
  MEMBERS_EDIT = "MEMBERS_EDIT",
  MEMBERS_PRODUCTS = "MEMBERS_PRODUCTS",

  MAILING_LISTS = "MAILING_LISTS",

  RANKS_READ = "RANKS_READ",
  RANKS_EDIT = "RANKS_EDIT",

  ORDERS_READ = "ORDERS_READ",
  ORDERS_EDIT = "ORDERS_EDIT",

  COMPENSATIONS_READ = "COMPENSATIONS_READ",
  COMPENSATIONS_CREATE = "COMPENSATIONS_CREATE",
  COMPENSATIONS_EDIT = "COMPENSATIONS_EDIT",
  COMPENSATIONS_APPROVE = "COMPENSATIONS_APPROVE",

  BILLINGREPORTS_READ = "BILLINGREPORTS_READ",
  BILLINGREPORTS_CREATE = "BILLINGREPORTS_CREATE",
  BILLINGREPORTS_EDIT = "BILLINGREPORTS_EDIT",
  BILLINGREPORTS_APPROVE = "BILLINGREPORTS_APPROVE",

  COLLECTIONPOINT_READ = "COLLECTIONPOINT_READ",
  COLLECTIONPOINT_CREATE = "COLLECTIONPOINT_CREATE",
  COLLECTIONPOINT_EDIT = "COLLECTIONPOINT_EDIT",

  PAYOUTS_READ = "PAYOUTS_READ",
  PAYOUTS_CREATE = "PAYOUTS_CREATE",
  PAYOUTS_SEND = "PAYOUTS_SEND",
  PAYOUTS_EDIT = "PAYOUTS_EDIT",

  LOGOFFS_READ = "LOGOFFS_READ",
  LOGOFFS_CREATE = "LOGOFFS_CREATE",
  LOGOFFS_EDIT = "LOGOFFS_EDIT",
  LOGOFFS_APPROVE = "LOGOFFS_APPROVE",

  PRODUCT_READ = "PRODUCT_READ",
  PRODUCT_EDIT = "PRODUCT_EDIT",

  MATERIAL_CHANGELOG_READ = "MATERIAL_CHANGELOG_READ",
  MATERIAL_CHANGELOG_CREATE = "MATERIAL_CHANGELOG_CREATE",
  MATERIAL_CHANGELOG_EDIT = "MATERIAL_CHANGELOG_EDIT",

  WAREHOUSE_READ = "WAREHOUSE_READ",
  WAREHOUSE_CREATE = "WAREHOUSE_CREATE",
	WAREHOUSE_OVERLOAD = 'warehouse_overload',

  ADMIN = "ADMIN",
}

import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ContactType = {
  __typename?: 'ContactType';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  bexioId: Scalars['Float'];
  name: Scalars['String'];
};


export type ContactGroup = {
  __typename?: 'ContactGroup';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  bexioId: Scalars['Float'];
  name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  outlookId?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  roles: Array<Authroles>;
  lastLogin: Scalars['DateTime'];
  bexioContact?: Maybe<Contact>;
  provider: Scalars['String'];
};

export enum Authroles {
  Authenticated = 'AUTHENTICATED',
  ContactsRead = 'CONTACTS_READ',
  ContactsEdit = 'CONTACTS_EDIT',
  MembersRead = 'MEMBERS_READ',
  MembersEdit = 'MEMBERS_EDIT',
  MembersProducts = 'MEMBERS_PRODUCTS',
  MailingLists = 'MAILING_LISTS',
  RanksRead = 'RANKS_READ',
  RanksEdit = 'RANKS_EDIT',
  OrdersRead = 'ORDERS_READ',
  OrdersEdit = 'ORDERS_EDIT',
  CompensationsRead = 'COMPENSATIONS_READ',
  CompensationsCreate = 'COMPENSATIONS_CREATE',
  CompensationsEdit = 'COMPENSATIONS_EDIT',
  CompensationsApprove = 'COMPENSATIONS_APPROVE',
  BillingreportsRead = 'BILLINGREPORTS_READ',
  BillingreportsCreate = 'BILLINGREPORTS_CREATE',
  BillingreportsEdit = 'BILLINGREPORTS_EDIT',
  BillingreportsApprove = 'BILLINGREPORTS_APPROVE',
  CollectionpointRead = 'COLLECTIONPOINT_READ',
  CollectionpointCreate = 'COLLECTIONPOINT_CREATE',
  CollectionpointEdit = 'COLLECTIONPOINT_EDIT',
  PayoutsRead = 'PAYOUTS_READ',
  PayoutsCreate = 'PAYOUTS_CREATE',
  PayoutsSend = 'PAYOUTS_SEND',
  PayoutsEdit = 'PAYOUTS_EDIT',
  LogoffsRead = 'LOGOFFS_READ',
  LogoffsCreate = 'LOGOFFS_CREATE',
  LogoffsEdit = 'LOGOFFS_EDIT',
  LogoffsApprove = 'LOGOFFS_APPROVE',
  ProductRead = 'PRODUCT_READ',
  ProductEdit = 'PRODUCT_EDIT',
  MaterialChangelogRead = 'MATERIAL_CHANGELOG_READ',
  MaterialChangelogCreate = 'MATERIAL_CHANGELOG_CREATE',
  MaterialChangelogEdit = 'MATERIAL_CHANGELOG_EDIT',
  WarehouseRead = 'WAREHOUSE_READ',
  WarehouseCreate = 'WAREHOUSE_CREATE',
  WarehouseOverload = 'WAREHOUSE_OVERLOAD',
  Admin = 'ADMIN'
}

export type Payout = {
  __typename?: 'Payout';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  compensations: Array<Compensation>;
  updatedBy: User;
  until: Scalars['DateTime'];
  from: Scalars['DateTime'];
  total: Scalars['Float'];
};

export type Compensation = {
  __typename?: 'Compensation';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  member: Contact;
  creator: User;
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  approved: Scalars['Boolean'];
  approvedBy: User;
  paied: Scalars['Boolean'];
  payout?: Maybe<Payout>;
  bexioBill?: Maybe<Scalars['Float']>;
  transferCompensation?: Maybe<Compensation>;
  updatedBy: User;
  deletedBy?: Maybe<User>;
  description: Scalars['String'];
};

export type CollectionPoint = {
  __typename?: 'CollectionPoint';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  city: Scalars['String'];
};

export type ContactExtension = {
  __typename?: 'ContactExtension';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  contactId: Scalars['Float'];
  collectionPointId: Scalars['Float'];
  entryDate: Scalars['DateTime'];
  exitDate: Scalars['DateTime'];
  bankName: Scalars['String'];
  iban: Scalars['String'];
  accountHolder: Scalars['String'];
  moreMails: Array<Scalars['String']>;
  updatedById: Scalars['Float'];
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  bexioId: Scalars['Float'];
  nr: Scalars['String'];
  contactType: ContactType;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  birthday: Scalars['DateTime'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  city: Scalars['String'];
  mail: Scalars['String'];
  mailSecond?: Maybe<Scalars['String']>;
  phoneFixed?: Maybe<Scalars['String']>;
  phoneFixedSecond?: Maybe<Scalars['String']>;
  phoneMobile?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  contactGroups: Array<ContactGroup>;
  ownerId: Scalars['Float'];
  compensations: Array<Compensation>;
  user?: Maybe<User>;
  rank?: Maybe<Scalars['String']>;
  functions?: Maybe<Array<Scalars['String']>>;
  collectionPoint?: Maybe<CollectionPoint>;
  entryDate?: Maybe<Scalars['DateTime']>;
  exitDate?: Maybe<Scalars['DateTime']>;
  bankName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  accountHolder?: Maybe<Scalars['String']>;
  moreMails?: Maybe<Array<Scalars['String']>>;
};

export type Position = {
  __typename?: 'Position';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  bexioId: Scalars['Float'];
  orderBexioId: Scalars['Float'];
  order: Order;
  positionType: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  pos?: Maybe<Scalars['String']>;
  internalPos?: Maybe<Scalars['Float']>;
  articleId?: Maybe<Scalars['Float']>;
  positionTotal?: Maybe<Scalars['Float']>;
};

export type OrderCompensation = {
  __typename?: 'OrderCompensation';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  member: Contact;
  creator: User;
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  approved: Scalars['Boolean'];
  approvedBy: User;
  paied: Scalars['Boolean'];
  payout?: Maybe<Payout>;
  bexioBill?: Maybe<Scalars['Float']>;
  transferCompensation?: Maybe<Compensation>;
  updatedBy: User;
  deletedBy?: Maybe<User>;
  description: Scalars['String'];
  billingReport: BillingReport;
  dayHours: Scalars['Float'];
  nightHours: Scalars['Float'];
  from: Scalars['DateTime'];
  until: Scalars['DateTime'];
  charge: Scalars['Boolean'];
  descriptionWithoutTime: Scalars['String'];
};

export type BillingReport = {
  __typename?: 'BillingReport';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  creator: User;
  order: Order;
  date: Scalars['DateTime'];
  compensations: Array<OrderCompensation>;
  els: Array<Contact>;
  drivers: Array<Contact>;
  approvedBy?: Maybe<User>;
  food: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  updatedBy: User;
  signature?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  bexioId: Scalars['Float'];
  documentNr: Scalars['String'];
  title: Scalars['String'];
  total: Scalars['Float'];
  validFrom?: Maybe<Scalars['DateTime']>;
  deliveryAddress: Scalars['String'];
  contact?: Maybe<Contact>;
  positions: Array<Position>;
  billingReports?: Maybe<Array<BillingReport>>;
  execDates: Array<Scalars['DateTime']>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  maxWeight?: Maybe<Scalars['Int']>;
};

export type File = {
  __typename?: 'File';
  name: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type MaterialChangelog = {
  __typename?: 'MaterialChangelog';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  creator: User;
  createdAt: Scalars['DateTime'];
  files: Array<File>;
  signature?: Maybe<Scalars['String']>;
  updatedBy: User;
  remarks?: Maybe<Scalars['String']>;
  changes: Array<MaterialChangelogToProduct>;
  inContact?: Maybe<Contact>;
  outContact?: Maybe<Contact>;
  inWarehouse?: Maybe<Warehouse>;
  outWarehouse?: Maybe<Warehouse>;
  in: ContactWarehouse;
  out: ContactWarehouse;
};

export type ContactWarehouse = Contact | Warehouse;

export type Product = {
  __typename?: 'Product';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  bexioId: Scalars['Float'];
  articleType?: Maybe<Scalars['Float']>;
  delivererCode?: Maybe<Scalars['String']>;
  delivererName?: Maybe<Scalars['String']>;
  delivererDescription?: Maybe<Scalars['String']>;
  internCode: Scalars['String'];
  internName: Scalars['String'];
  internDescription?: Maybe<Scalars['String']>;
  purchasePrice?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Float']>;
  purchaseTotal?: Maybe<Scalars['Float']>;
  saleTotal?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<Scalars['Float']>;
  articleGroupId?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  contact?: Maybe<Contact>;
  locations: Array<StockEntry>;
  changelogs: Array<MaterialChangelog>;
};

export type MaterialChangelogToProduct = {
  __typename?: 'MaterialChangelogToProduct';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  amount: Scalars['Float'];
  charge: Scalars['Boolean'];
  number?: Maybe<Scalars['Int']>;
  product: Product;
  changelog: MaterialChangelog;
  compensation?: Maybe<CustomCompensation>;
};

export type CustomCompensation = {
  __typename?: 'CustomCompensation';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  member: Contact;
  creator: User;
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  approved: Scalars['Boolean'];
  approvedBy: User;
  paied: Scalars['Boolean'];
  payout?: Maybe<Payout>;
  bexioBill?: Maybe<Scalars['Float']>;
  transferCompensation?: Maybe<Compensation>;
  updatedBy: User;
  deletedBy?: Maybe<User>;
  description: Scalars['String'];
  materialChangelogToProducts?: Maybe<Array<MaterialChangelogToProduct>>;
};

export type Logoff = {
  __typename?: 'Logoff';
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  contact: Contact;
  from: Scalars['DateTime'];
  until: Scalars['DateTime'];
  state: LogoffState;
  remarks?: Maybe<Scalars['String']>;
  createdBy: User;
  createdAt?: Maybe<Scalars['DateTime']>;
  changedStateBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<User>;
};

/** Possible states for logoffs */
export enum LogoffState {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Declined = 'DECLINED'
}

export type MaterialChangelog2WarehouseView = {
  __typename?: 'MaterialChangelog2WarehouseView';
  inAmount: Scalars['Float'];
  outAmount: Scalars['Float'];
  product: Product;
  warehouse: Warehouse;
};

export type MaterialChangelog2ContactView = {
  __typename?: 'MaterialChangelog2ContactView';
  inAmount: Scalars['Float'];
  outAmount: Scalars['Float'];
  product: Product;
  contact: Contact;
};

export type StockEntry = {
  __typename?: 'StockEntry';
  productName: Scalars['String'];
  amount: Scalars['Float'];
  location: Scalars['String'];
  price: Scalars['Float'];
};

export type PaginationFilter = {
  __typename?: 'PaginationFilter';
  id: Scalars['Int'];
  field: Scalars['String'];
  operator: Scalars['String'];
  value: Scalars['String'];
  displayName: Scalars['String'];
};

export type PaginationResponseBillingReport = {
  __typename?: 'PaginationResponseBillingReport';
  items: Array<BillingReport>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseBillingReportClone = {
  __typename?: 'PaginationResponseBillingReportClone';
  items: Array<BillingReport>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseCollectionPoint = {
  __typename?: 'PaginationResponseCollectionPoint';
  items: Array<CollectionPoint>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseCompensation = {
  __typename?: 'PaginationResponseCompensation';
  items: Array<Compensation>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseContact = {
  __typename?: 'PaginationResponseContact';
  items: Array<Contact>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseMember = {
  __typename?: 'PaginationResponseMember';
  items: Array<Contact>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseContactGroup = {
  __typename?: 'PaginationResponseContactGroup';
  items: Array<ContactGroup>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseContactType = {
  __typename?: 'PaginationResponseContactType';
  items: Array<ContactType>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseLogoff = {
  __typename?: 'PaginationResponseLogoff';
  items: Array<Logoff>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseMaterialChangelogToProduct = {
  __typename?: 'PaginationResponseMaterialChangelogToProduct';
  items: Array<MaterialChangelogToProduct>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseMaterialChangelog = {
  __typename?: 'PaginationResponseMaterialChangelog';
  items: Array<MaterialChangelog>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseOrder = {
  __typename?: 'PaginationResponseOrder';
  items: Array<Order>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponsePayout = {
  __typename?: 'PaginationResponsePayout';
  items: Array<Payout>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponsePosition = {
  __typename?: 'PaginationResponsePosition';
  items: Array<Position>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseProduct = {
  __typename?: 'PaginationResponseProduct';
  items: Array<Product>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseUser = {
  __typename?: 'PaginationResponseUser';
  items: Array<User>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type PaginationResponseWarehouse = {
  __typename?: 'PaginationResponseWarehouse';
  items: Array<Warehouse>;
  total: Scalars['Int'];
  cursor: Scalars['Int'];
  hasMore: Scalars['Boolean'];
};

export type FileInput = {
  name: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type InputPaginationFilter = {
  field: Scalars['String'];
  operator: Scalars['String'];
  value: Scalars['String'];
};

export type AddBillingReportInput = {
  orderId: Scalars['Float'];
  date: Scalars['DateTime'];
  elIds: Array<Scalars['Float']>;
  driverIds: Array<Scalars['Float']>;
  food: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
};

export type EditBillingReportInput = {
  id: Scalars['Float'];
  orderId?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
  elIds?: Maybe<Array<Scalars['Float']>>;
  driverIds?: Maybe<Array<Scalars['Float']>>;
  food?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
};

export type AddCollectionPoint = {
  name: Scalars['String'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  city: Scalars['String'];
};

export type EditCollectionPoint = {
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type EditContact = {
  id: Scalars['Float'];
  collectionPointId?: Maybe<Scalars['Float']>;
  entryDate?: Maybe<Scalars['DateTime']>;
  exitDate?: Maybe<Scalars['DateTime']>;
  bankName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  accountHolder?: Maybe<Scalars['String']>;
  moreMails?: Maybe<Array<Scalars['String']>>;
};

export type AddCustomCompensation = {
  description: Scalars['String'];
  memberId: Scalars['Float'];
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
};

export type AddLogoff = {
  contactId: Scalars['Float'];
  from: Scalars['DateTime'];
  until: Scalars['DateTime'];
  state: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
};

export type AddMaterialChangelogToProduct = {
  productId: Scalars['Float'];
  amount: Scalars['Float'];
  number?: Maybe<Scalars['Float']>;
  charge: Scalars['Boolean'];
  changelogId: Scalars['Float'];
};

export type AddMaterialChangelog = {
  products: Array<AddMaterialChangelogToProduct>;
  in?: Maybe<Scalars['Float']>;
  inType: MaterialChangelogInOutType;
  out?: Maybe<Scalars['Float']>;
  outType: MaterialChangelogInOutType;
  date: Scalars['DateTime'];
  files: Array<FileInput>;
  signature?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export enum MaterialChangelogInOutType {
  Contact = 'CONTACT',
  Warehouse = 'WAREHOUSE'
}

export type AddOrderCompensation = {
  from: Scalars['DateTime'];
  until: Scalars['DateTime'];
  billingReportId: Scalars['Float'];
  memberId: Scalars['Float'];
  date: Scalars['DateTime'];
};

export type EditProduct = {
  id: Scalars['Float'];
  weight: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  getAllBillingReports: PaginationResponseBillingReportClone;
  getBillingReport?: Maybe<BillingReport>;
  getBillingReportFilters?: Maybe<Array<PaginationFilter>>;
  getAllCollectionPoints: PaginationResponseCollectionPoint;
  getCollectionPoint?: Maybe<CollectionPoint>;
  getCollectionPointFilters?: Maybe<Array<PaginationFilter>>;
  getCollectionPoints: Array<CollectionPoint>;
  getAllCompensations: PaginationResponseCompensation;
  getCompensation?: Maybe<Compensation>;
  getCompensationFilters?: Maybe<Array<PaginationFilter>>;
  getContactCompensations: Array<Compensation>;
  getAllContacts: PaginationResponseContact;
  getContact?: Maybe<Contact>;
  getContactFilters?: Maybe<Array<PaginationFilter>>;
  getMembers: PaginationResponseMember;
  getMembersAll: Array<Contact>;
  getMembersDropdown: Array<Contact>;
  getSuppliersAll: Array<Contact>;
  getAllContactGroups: PaginationResponseContactGroup;
  getContactGroup?: Maybe<ContactGroup>;
  getContactGroupFilters?: Maybe<Array<PaginationFilter>>;
  getRanks: Array<ContactGroup>;
  getAllContactTypes: PaginationResponseContactType;
  getContactType?: Maybe<ContactType>;
  getContactTypeFilters?: Maybe<Array<PaginationFilter>>;
  getCustomCompensation?: Maybe<CustomCompensation>;
  getAllLogoffs: PaginationResponseLogoff;
  getLogoff?: Maybe<Logoff>;
  getLogoffFilters?: Maybe<Array<PaginationFilter>>;
  getContactLogoffs: Array<Logoff>;
  getAllMaterialChangelogToProducts: PaginationResponseMaterialChangelogToProduct;
  getMaterialChangelogToProduct?: Maybe<MaterialChangelogToProduct>;
  getMaterialChangelogToProductFilters?: Maybe<Array<PaginationFilter>>;
  getAllMaterialChangelogs: PaginationResponseMaterialChangelog;
  getMaterialChangelog?: Maybe<MaterialChangelog>;
  getMaterialChangelogFilters?: Maybe<Array<PaginationFilter>>;
  getContactMaterialChangelogs: Array<MaterialChangelog>;
  getContactStock?: Maybe<Array<MaterialChangelog2ContactView>>;
  getWarehouseStock?: Maybe<Array<MaterialChangelog2WarehouseView>>;
  getAllOrders: PaginationResponseOrder;
  getOrder?: Maybe<Order>;
  getOrderFilters?: Maybe<Array<PaginationFilter>>;
  getOpenOrders: Array<Order>;
  getOrderCompensation?: Maybe<OrderCompensation>;
  getAllPayouts: PaginationResponsePayout;
  getPayout?: Maybe<Payout>;
  getPayoutFilters?: Maybe<Array<PaginationFilter>>;
  getAllPositions: PaginationResponsePosition;
  getPosition?: Maybe<Position>;
  getPositionFilters?: Maybe<Array<PaginationFilter>>;
  getAllProducts: PaginationResponseProduct;
  getProduct?: Maybe<Product>;
  getProductFilters?: Maybe<Array<PaginationFilter>>;
  getProductsAll: Array<Product>;
  getAllUsers: PaginationResponseUser;
  getUser?: Maybe<User>;
  getUserFilters?: Maybe<Array<PaginationFilter>>;
  me: User;
  getAllWarehouses: PaginationResponseWarehouse;
  getWarehouse?: Maybe<Warehouse>;
  getWarehouseFilters?: Maybe<Array<PaginationFilter>>;
  getWarehousesAll: Array<Warehouse>;
};


export type QueryGetAllBillingReportsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetBillingReportArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllCollectionPointsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetCollectionPointArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllCompensationsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetCompensationArgs = {
  id: Scalars['Int'];
};


export type QueryGetContactCompensationsArgs = {
  payoutId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type QueryGetAllContactsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetContactArgs = {
  id: Scalars['Int'];
};


export type QueryGetMembersArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetAllContactGroupsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetContactGroupArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllContactTypesArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetContactTypeArgs = {
  id: Scalars['Int'];
};


export type QueryGetCustomCompensationArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllLogoffsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetLogoffArgs = {
  id: Scalars['Int'];
};


export type QueryGetContactLogoffsArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllMaterialChangelogToProductsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetMaterialChangelogToProductArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllMaterialChangelogsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetMaterialChangelogArgs = {
  id: Scalars['Int'];
};


export type QueryGetContactMaterialChangelogsArgs = {
  id: Scalars['Int'];
};


export type QueryGetContactStockArgs = {
  contactId: Scalars['ID'];
};


export type QueryGetWarehouseStockArgs = {
  warehouseId: Scalars['ID'];
};


export type QueryGetAllOrdersArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetOrderArgs = {
  id: Scalars['Int'];
};


export type QueryGetOrderCompensationArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllPayoutsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetPayoutArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllPositionsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetPositionArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllProductsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetProductArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllUsersArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetAllWarehousesArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchString?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['Int']>;
  customFilter?: Maybe<Array<InputPaginationFilter>>;
};


export type QueryGetWarehouseArgs = {
  id: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  editBillingReport: BillingReport;
  addBillingReport: BillingReport;
  changeBillingReportState: BillingReport;
  signBillingReport: BillingReport;
  sendBillingReportReceiptMail: Scalars['Boolean'];
  addCollectionPoint: CollectionPoint;
  editCollectionPoint: CollectionPoint;
  deleteCompensation: Compensation;
  approveCompensation: Compensation;
  editContact: ContactExtension;
  addCustomCompensation: CustomCompensation;
  uploadFile: File;
  deleteLogoff: Logoff;
  changeLogoffState: Logoff;
  addLogoffs: Array<Logoff>;
  addLogoff: Logoff;
  addMaterialChangelogToProduct: MaterialChangelogToProduct;
  deleteMaterialChangelog: MaterialChangelog;
  addMaterialChangelog: MaterialChangelog;
  sendMaterialChangelogReceiptMail: Scalars['Boolean'];
  addOrderCompensations: Array<OrderCompensation>;
  addOrderCompensation: OrderCompensation;
  transferPayout: Scalars['Boolean'];
  send2Bexio: Scalars['Boolean'];
  sendPayoutMails: Scalars['Boolean'];
  reclaimPayout: Payout;
  addPayout: Payout;
  markAsPaied: Scalars['Boolean'];
  markAsPaiedBulk: Scalars['Boolean'];
  editProduct: Product;
  addWarehouse: Warehouse;
  editWarehouse: Warehouse;
};


export type MutationEditBillingReportArgs = {
  data: EditBillingReportInput;
};


export type MutationAddBillingReportArgs = {
  data: AddBillingReportInput;
};


export type MutationChangeBillingReportStateArgs = {
  state: BillingReportState;
  id: Scalars['Int'];
};


export type MutationSignBillingReportArgs = {
  signature: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationSendBillingReportReceiptMailArgs = {
  id: Scalars['Int'];
};


export type MutationAddCollectionPointArgs = {
  data: AddCollectionPoint;
};


export type MutationEditCollectionPointArgs = {
  data: EditCollectionPoint;
};


export type MutationDeleteCompensationArgs = {
  id: Scalars['Int'];
};


export type MutationApproveCompensationArgs = {
  id: Scalars['Int'];
};


export type MutationEditContactArgs = {
  data: EditContact;
};


export type MutationAddCustomCompensationArgs = {
  data: AddCustomCompensation;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationDeleteLogoffArgs = {
  notify?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};


export type MutationChangeLogoffStateArgs = {
  notify?: Maybe<Scalars['Boolean']>;
  state: LogoffState;
  id: Scalars['Int'];
};


export type MutationAddLogoffsArgs = {
  notify?: Maybe<Scalars['Boolean']>;
  data: Array<AddLogoff>;
};


export type MutationAddLogoffArgs = {
  notify?: Maybe<Scalars['Boolean']>;
  data: AddLogoff;
};


export type MutationAddMaterialChangelogToProductArgs = {
  data: AddMaterialChangelogToProduct;
};


export type MutationDeleteMaterialChangelogArgs = {
  id: Scalars['Int'];
};


export type MutationAddMaterialChangelogArgs = {
  data: AddMaterialChangelog;
};


export type MutationSendMaterialChangelogReceiptMailArgs = {
  id: Scalars['Int'];
};


export type MutationAddOrderCompensationsArgs = {
  data: Array<AddOrderCompensation>;
};


export type MutationAddOrderCompensationArgs = {
  data: AddOrderCompensation;
};


export type MutationTransferPayoutArgs = {
  memberIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationSend2BexioArgs = {
  memberIds?: Maybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
};


export type MutationSendPayoutMailsArgs = {
  memberIds?: Maybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
};


export type MutationReclaimPayoutArgs = {
  id: Scalars['Int'];
};


export type MutationAddPayoutArgs = {
  from?: Maybe<Scalars['DateTime']>;
  until: Scalars['DateTime'];
};


export type MutationMarkAsPaiedArgs = {
  memberId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationMarkAsPaiedBulkArgs = {
  memberIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationEditProductArgs = {
  data: EditProduct;
};


export type MutationAddWarehouseArgs = {
  maxWeight?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};


export type MutationEditWarehouseArgs = {
  maxWeight?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  id: Scalars['Int'];
};

/** Possilbe states for billingReports */
export enum BillingReportState {
  Unsigned = 'UNSIGNED',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}



      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "ContactWarehouse",
        "possibleTypes": [
          {
            "name": "Contact"
          },
          {
            "name": "Warehouse"
          }
        ]
      }
    ]
  }
};
      export default result;
    
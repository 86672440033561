import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBoxes,
  faExchangeAlt,
  faWarehouse,
  faUserTimes,
  faCheckCircle,
  faFileInvoiceDollar,
  faFileWord,
  faFilePdf,
  faExternalLinkAlt,
  faFileExcel,
  faFileCsv,
  faPowerOff,
  faTrash,
  faSave,
  faAddressBook,
  faMapMarkerAlt,
  faDownload,
  faClipboard,
  faBars,
  faTachometerAlt,
  faUsers,
  faCircleNotch,
  faTimes,
  faCheck,
  faSortUp,
  faSortDown,
  faPencilAlt,
  faFileAlt,
  faEye,
  faFileSignature,
  faPlus,
  faList,
  faDollarSign,
  faUser,
  faAngleLeft,
  faAngleDown,
  faMailBulk,
  faLongArrowAltRight,
  faExclamationCircle,
  faTimesCircle,
  faQrcode,
  faLightbulb,
  faCubes,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
//@ts-ignore
library.add(
  fab,
  faBoxes,
  faExchangeAlt,
  faWarehouse,
  faUserTimes,
  faCheckCircle,
  faFileInvoiceDollar,
  faFileWord,
  faFilePdf,
  faExternalLinkAlt,
  faFileExcel,
  faPowerOff,
  faTrash,
  faSave,
  faAddressBook,
  faMapMarkerAlt,
  faDownload,
  faClipboard,
  faBars,
  faTachometerAlt,
  faUsers,
  faCircleNotch,
  faTimes,
  faCheck,
  faSortUp,
  faSortDown,
  faPencilAlt,
  faFileAlt,
  faEye,
  faFileSignature,
  faPlus,
  faList,
  faDollarSign,
  faDollarSign,
  faUser,
  faAngleLeft,
  faAngleDown,
  faMailBulk,
  faLongArrowAltRight,
  faExclamationCircle,
  faTimesCircle,
  faQrcode,
  faLightbulb,
  faCubes,
  faChartPie,
  faFileCsv
);
